<template>
  <div class="explainbox">
    <ul class="corpus-title zhengwen11" @click="changeactive">
      <li :class="activeid == 1 ? 'title active' : 'title'" data-id="1">
        全部
      </li>
      <li :class="activeid == 2 ? 'title active' : 'title'" data-id="2">
        文学
      </li>
      <li :class="activeid == 3 ? 'title active' : 'title'" data-id="3">
        新闻
      </li>
      <li :class="activeid == 4 ? 'title active' : 'title'" data-id="4">
        官方文件
      </li>
      <!-- <li :class="activeid == 5 ? 'title active' : 'title'" data-id="5">
        政治
      </li> -->
    </ul>
    <div class="shiyi_four" id="shiyi_four">
      <div
        v-infinite-scroll="newload"
        infinite-scroll-disabled="newdisabled"
        infinite-scroll-distance="10"
        class="listscroll"
      >
        <div class="corpus-item" v-for="(item, index) in showList" :key="index">
          <p class="zhengwen1 explain1">
            {{ index + 1 }}.<span
              @click="getShiYiNew($event)"
              :class="!isChinese ? 'dinRegular cursorpointer ' : 'zhengwen2'"
              @mouseover.stop="setColor($event)"
              @mouseout.stop="delColor($event)"
              v-html="brightenKeyword(addElementSpan(item.hit), word)"
            ></span>
          </p>
          <p
            class=""
            @click="getShiYiNew($event)"
            :class="
              isChinese ? 'dinRegular cursorpointer ' : 'zhengwen2 explain2'
            "
            @mouseover.stop="setColor($event)"
            @mouseout.stop="delColor($event)"
            v-html="brightenKeyword(addElementSpan(item.targetContents), word)"
          ></p>
          <p class="zhengwen2" style="text-align: right">
            出自
            <!-- <span class="beizhufont4">《{{ item.name }}》</span> -->

            <el-popover
              popper-class="selpopper"
              placement="bottom"
              width="226"
              trigger="click"
              :append-to-body="false"
            >
              <div class="font16400 grey1">
                <el-descriptions
                  :column="1"
                  contentClassName="selcontent"
                  labelClassName="selcontent"
                >
                  <el-descriptions-item
                    contentClassName="titlefont2 "
                    labelStyle="display:none"
                    >书籍详情</el-descriptions-item
                  >
                  <el-descriptions-item label="作者">{{
                    item.author
                  }}</el-descriptions-item>
                  <el-descriptions-item label="等级">{{
                    item.level
                  }}</el-descriptions-item>
                  <el-descriptions-item label="体裁">{{
                    item.genre
                  }}</el-descriptions-item>

                  <el-descriptions-item label="题材">{{
                    item.theme
                  }}</el-descriptions-item>
                  <el-descriptions-item label="语言">{{
                    item.originalLang
                  }}</el-descriptions-item>
                </el-descriptions>
              </div>
              <span slot="reference" class="beizhufont4 cursorp"
                >《{{ item.name }}》</span
              >
            </el-popover>
          </p>
        </div>
        <div style="margin: 10px 0">
          <p style="text-align: center" v-if="newloading">加载中...</p>
          <p style="text-align: center" v-if="noMore">沒有更多内容了</p>
        </div>
      </div>
      <!-- <el-pagination
        :page-size="searchquery.pageSize"
        :current-page="searchquery.pageNum"
        hide-on-single-page
        background
        layout="prev, pager, next"
        :total="searchtotal"
        prev-text="上一页"
        next-text="下一页"
        @current-change="changePage"
      >
      </el-pagination> -->
      <div
        class="mobilebox"
        v-show="isShowShiYiBox"
        :style="{ left: mouseX + 'px', top: mouseY + 'px' }"
      >
        <div class="shiyititle">
          <p class="font20500 black1" v-if="!isShezhi">{{ selectarr.word }}</p>
          <p class="font20500 black1" v-if="isShezhi">设置</p>
          <div class="buttonlist">
            <img
              style="margin-right: 10px"
              src="./images/shezhi.png"
              alt=""
              @click="isShezhi = !isShezhi"
            /><img src="./images/close.png" alt="" @click="closeMobileBoxis" />
          </div>
        </div>
        <div class="shiyicontent" v-if="!isShezhi">
          <p class="font18400 grey1">
            {{ selectarr.meaning }}
          </p>
        </div>
        <div class="shiyibottm" v-if="!isShezhi">
          <p
            class="font16400 green1 cursorpointer"
            @click="detailShiyi(selectarr.origin)"
          >
            详细释义
          </p>
        </div>
        <div class="shiyibottm shiyibottm2" v-if="isShezhi">
          <p class="font16400 grey1">开启屏幕取词</p>
          <el-switch v-model="canGetWord" active-color="#1890FF"> </el-switch>
        </div>
      </div>
    </div>
    <!-- <div class="corpus-item">
      <p class="zhengwen1 explain1">
        1.sentada en la cama, mirando la pared,y elentro(estaba limpio, olia
        bien) y se qued junto a mi, sindecir nada, solo dijo hola, Sofia, Y se
        qued allf de piehasta que vo deie dellorar.
      </p>
      <p class="zhengwen2 explain2">
        我正坐在床边，盯着墙壁，他走进来(他身上很干净，味道挺好闻)，站在我身边，不说一句话，他只说了句你好，索菲娅，就一直那样站着等到我不哭了。
      </p>
      <p class="zhengwen2" style="text-align: right">
        出自<span class="beizhufont4">《EI prisinoero del cielo》</span>
      </p>
    </div>
    <div class="corpus-item">
      <p class="zhengwen1 explain1">
        1.sentada en la cama, mirando la pared,y elentro(estaba limpio, olia
        bien) y se qued junto a mi, sindecir nada, solo dijo hola, Sofia, Y se
        qued allf de piehasta que vo deie dellorar.
      </p>
      <p class="zhengwen2 explain2">
        我正坐在床边，盯着墙壁，他走进来(他身上很干净，味道挺好闻)，站在我身边，不说一句话，他只说了句你好，索菲娅，就一直那样站着等到我不哭了。
      </p>
      <p class="zhengwen2" style="text-align: right">
        出自<span class="beizhufont4">《EI prisinoero del cielo》</span>
      </p>
    </div> -->
  </div>
</template>

<script>
import {
  searchCorpus, screenQuery
} from "@/api"
export default {
  name: "Corpus",
  data () {
    return {
      newloading: false, //滚动加载
      // isChinese: false,
      activeid: 1,
      showList: [],
      searchquery: {
        keyword: '',
        direction: 'es_to_ch',
        pageNum: 1,
        pageSize: 20
      },
      searchtotal: 0,
      list_jt_total: [],
      list_jt_literature: [],
      list_jt_news: [],
      list_jt_official: [],
      // list_jt_politics: []
      judgeTimer: '',
      selectword: '',
      selectarr: {},
      canGetWord: true,  //是否可取词
      isShezhi: false,//是否展示设置
      isShowShiYiBox: false,//屏幕取词box
      mouseX: '',
      mouseY: '',
    }
  },
  props: ['word', 'isChinese'],
  computed: {
    noMore () {
      return this.searchtotal < this.searchquery.pageSize
    },
    newdisabled () {
      return this.newloading || this.noMore
    },
  },
  mounted () {
    // if (this.word) {
    //   this.searchquery.keyword = this.word
    //   this.getSearchCorpus('jt_total')
    //   this.getSearchCorpus('jt_literature')
    //   this.getSearchCorpus('jt_news')
    //   this.getSearchCorpus('jt_official')
    // }
    // this.isChinese = this.word.charCodeAt(0) > 255
    // console.log(this.word, 'this.wordcorpus')

  },
  methods: {
    detailShiyi (origin) {
      // this.$emit("changeword", this.selectword)
      // this.isShowShiYiBox = false

      let selword = origin || this.selectword
      this.$emit("changeword", selword)
      this.isShowShiYiBox = false
    },
    closeMobileBoxis () {
      this.isShowShiYiBox = false
    },
    //旧版屏幕取词
    getShiYi (e) {
      // console.log(this.selectword, 'this.selectword')
      this.selectarr = []
      if (this.selectword.indexOf(' ') > '-1') {
        return
      }
      if (this.selectword.charCodeAt(0) > 255) {
        return
      }
      // if (this.isShezhi) this.isShowShiYiBox = true
      this.isShowShiYiBox = true

      let element = document.getElementById("shiyi_four")

      let rect = element.getBoundingClientRect()
      let threetop = rect.top
      let threeleft = rect.left
      this.mouseX = e.clientX - threeleft - 40
      this.mouseY = e.clientY - threetop + 10

      if (this.canGetWord && this.selectword) {

        screenQuery({ word: this.selectword }).then((d) => {
          // console.log(d, 'ddd')
          if (d.code == 200) {
            if (d.data) {
              // this.isShowShiYiBox = true
              this.selectarr = d.data
            }

          } else {
            this.$message.error(d.msg)
          }
        })
      }

    },
    //新版屏幕取词弹窗
    getShiYiNew (e) {
      this.selectarr = []
      if (this.selectword.indexOf(' ') > '-1') {
        return
      }
      if (this.selectword.charCodeAt(0) > 255) {
        return
      }
      if (this.selectword) {
        this.$emit("changeCreenWord", this.selectword)


      }

    },
    setColor (e) {


      //、、 this.selectarr = {}
      this.selectword = e.target.innerText
      // console.log(e.target, e.target.innerText, 'this.selectword')
      // const textContent = window.getSelection()
      // console.log(textContent, 'textContent')
      if (this.selectword.charCodeAt(0) <= 255) {
        clearTimeout(this.judgeTimer)
        if (e.target.innerText.indexOf(' ') == '-1') {

          this.judgeTimer = setTimeout(function () {

            e.target ? e.target.classList.add('wordhighlight') : ''
            this.eleClick = e
          }, 500)

        }
      }
    },
    delColor (e) {

      clearTimeout(this.judgeTimer)
      if (!this.isShowShiYiBox) {
        e.target.classList.remove('wordhighlight')
        // this.selectword = ''
      }
    },
    // ?keyword=ho&direction=es_to_ch&group=jt_total&pageNum=1&pageSize=15
    brightenKeyword (val, keyword) {
      const Reg = new RegExp(keyword, "gi")
      if (val) {
        return val.replace(
          Reg,
          `<span style="color: rgba(67, 207, 124, 1);
">${keyword}</span>`
        )
      }
    },
    addElementSpan (str = '') {

      return str.split(' ').
        map((item) => {
          const { start, word, end } = this.getWord(item)
          return `${start}<span>${word}</span>${end} `
          // return `<span>${item}</span> `
        })
        .join('')
    },
    getWord (str = '') {
      let word = ''
      let start = ''
      let end = ''
      let j = str.length - 1
      let i = 0

      while (i < str.length) {
        if (/^[a-zA-Z0-9ñáéíóúüÑÁÉÍÓÚ]$/.test(str[i])) {
          break
        }
        start = start + str[i]
        i += 1
      }

      while (j >= 0) {
        if (/^[a-zA-Z0-9ñáéíóúüÑÁÉÍÓÚ]$/.test(str[j])) {
          break
        }
        end = str[j] + end
        j -= 1
      }

      word = str.slice(i, j + 1)

      // 处理数字
      if (!word && start === end) {
        start = ''
      }

      return {
        start,
        word,
        end
      }
    }

    ,
    // changePage (pageNum) {
    //   this.searchquery.pageNum = pageNum
    //   if (this.activeid == 1) {
    //     this.getSearchCorpus('jt_total')
    //   }
    //   if (this.activeid == 2) {
    //     this.getSearchCorpus('jt_literature')
    //   }
    //   if (this.activeid == 3) {
    //     this.getSearchCorpus('jt_news')
    //   }
    //   if (this.activeid == 4) {
    //     this.getSearchCorpus('jt_official')
    //   }

    // },
    newload () {
      this.newloading = true
      setTimeout(() => {

        this.searchquery.pageNum += 1
        if (this.activeid == 1) {
          this.getSearchCorpus('jt_total')
        }
        if (this.activeid == 2) {
          this.getSearchCorpus('jt_literature')
        }
        if (this.activeid == 3) {
          this.getSearchCorpus('jt_news')
        }
        if (this.activeid == 4) {
          this.getSearchCorpus('jt_official')
        }

        // this.newloading = false

      }, 2000)
    },
    getSearchCorpus (group) {
      let params = this.searchquery
      params.group = group
      // console.log(this.isChinese, 'this.isChinese')
      params.direction = this.isChinese ? 'ch_to_es' : 'es_to_ch'
      searchCorpus(params).then((d) => {
        if (d.code == 200) {
          this.searchtotal = d.data.length
          this.showList = [...this.showList, ...d.data]
          this[`list_${group}`] = d.data
          this.newloading = false

          // if (this.activeid == 1 && group == 'jt_total') {
          //   this.showList = d.data
          // }
          // if (this.activeid == 2 && group == 'jt_literature') {
          //   this.showList = d.data
          // }
          // if (this.activeid == 3 && group == 'jt_news') {
          //   this.showList = d.data
          // }
          // if (this.activeid == 4 && group == 'jt_official') {
          //   this.showList = d.data
          // }


        }

      })

    },
    changeactive (e) {
      let id = e.target.dataset.id
      this.activeid = id
      this.searchquery.pageNum = 1
      this.showList = []
      if (id == 1) {
        // this.showList = this.list_jt_total
        this.getSearchCorpus('jt_total')

      } else if (id == 2) {
        // this.showList = this.list_jt_literature
        this.getSearchCorpus('jt_literature')
      }
      else if (id == 3) {
        // this.showList = this.list_jt_news
        this.getSearchCorpus('jt_news')
      }
      else if (id == 4) {
        // this.showList = this.list_jt_official
        this.getSearchCorpus('jt_official')
      }

    }
  },
  watch: {
    word: {
      handler (newVal, oldVal) {
        if (newVal) {
          // console.log(newVal, 'newVal')

          // this.getSearchCorpus('jt_total')
          // this.getSearchCorpus('jt_literature')
          // this.getSearchCorpus('jt_news')
          // this.getSearchCorpus('jt_official')
          this.searchquery.keyword = newVal
          this.searchquery.pageNum = 1
          this.activeid = 1
          // this.showList = this.list_jt_total
          this.showList = []
          this.getSearchCorpus('jt_total')

        }

      },
      immediate: true
    }
  }

}
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #eee;
}
/*定义滑块 样式*/
::-webkit-scrollbar-thumb {
  height: 100px; /* 滚动条滑块长度 */
  background-color: #ccc;
}
// /deep/.el-pagination {
//   text-align: center;
//   margin: 20px 0 40px;
// }
// /deep/.el-pagination.is-background .btn-next,
// /deep/.el-pagination.is-background .btn-prev,
// /deep/.el-pagination.is-background .el-pager li {
//   height: 32px;
//   line-height: 32px;
//   opacity: 1;
//   border-radius: 8px;
//   background: rgba(255, 255, 255, 0.6);
//   border: 1px solid rgba(255, 255, 255, 1);
//   backdrop-filter: blur(20px);

//   font-size: 14px;
//   font-weight: 400;
//   color: rgba(119, 119, 119, 1);

//   padding: 0 12px;
// }
// /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
//   height: 32px;
//   background: rgba(67, 207, 124, 1);
//   color: rgba(255, 255, 255, 1);
//   border: none;
// }
/deep/.wordhighlight {
  height: 24px;
  // padding: 0 2px;
  border-radius: 4px;
  background: rgba(173, 255, 206, 0.6);
}
.explainbox {
  position: relative;
}
.shiyi_four {
  position: relative;
}
.listscroll {
  margin-top: 15px;
  height: 75vh;
  overflow-y: auto;
}
.mobilebox {
  width: 226px;
  // height: 183.5px;
  padding: 16px;
  opacity: 1;
  background: rgba(255, 255, 255, 0.8);

  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 12px;

  box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
  backdrop-filter: blur(15px);
  position: absolute;

  .shiyititle {
    display: flex;
    justify-content: space-between;
    .buttonlist {
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
  .shiyicontent {
    margin: 10px 0;
  }
  .shiyibottm {
    height: 27px;
    width: 100%;
    border-top: 1px dashed rgba(204, 204, 204, 1);
    text-align: center;
    padding-top: 8px;
  }
  .shiyibottm2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
/deep/.selcontent {
  font-size: 16px;
  font-weight: 400;
  color: rgba(119, 119, 119, 1);
}
/deep/.el-descriptions__body {
  background: rgba(255, 255, 255, 0.8);
}
/deep/.selpopper {
  border-radius: 8px;
  // background: rgba(255, 255, 255, 0.8);

  border: 2px solid rgba(255, 255, 255, 1);

  box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
}
.cursorp {
  cursor: pointer;
}
.corpus-title {
  display: flex;
  padding-top: 22px;
  li {
    // width: 60px;
    display: block;
    height: 32px;
    cursor: pointer;
    line-height: 32px;
    opacity: 1;
    border-radius: 4px;

    border: 1px solid rgba(238, 238, 238, 1);
    // display: flex;
    // justify-content: flex-start;
    // align-items: flex-start;
    padding: 5px 14px 5px 14px;
    margin-right: 20px;
  }
  .title {
    background: rgba(255, 255, 255, 1);
  }
  .active {
    background: rgba(67, 207, 124, 1);
    color: rgba(255, 255, 255, 1);
  }
}
.corpus-item {
  width: 100%;
  padding: 20px 0;
  border-bottom: 2px dashed rgba(255, 255, 255, 1);
}
</style>
<template>
  <div class="dinRegular">
    <div class="title-box">
      <ul class="change-title zhengwen11" @click="handleclick">
        <li class="title" :style="{ background: colorList[0] }" data-id="1">
          陈述式
        </li>
        <li class="title" :style="{ background: colorList[1] }" data-id="2">
          虚拟式
        </li>
        <li class="title" :style="{ background: colorList[2] }" data-id="3">
          命令式
        </li>
        <li class="title" :style="{ background: colorList[3] }" data-id="4">
          条件式
        </li>
        <li class="title" :style="{ background: colorList[4] }" data-id="5">
          无人称
        </li>
      </ul>
      <!-- <div class="illustrate titlefont14" @click="showDes"><p>变位说明</p></div> -->
    </div>
    <div v-show="active == 1">
      <div class="table titlefont13" v-show="oneVerbList1 != 0">
        <ul class="title" :style="{ background: colorList[active - 1] }">
          <li><p>人称</p></li>
          <li>
            <p>陈述式现在时</p>
            <p>indicativo Presente</p>
          </li>
          <li>
            <p>陈述现在完成时</p>
            <p>Indicativo Perfecto</p>
          </li>
        </ul>
        <ul v-for="(item, index) in oneVerbList1" :key="index">
          <li>
            <p>{{ item.a }}</p>
          </li>
          <li>
            <p>{{ item.b }}</p>
          </li>
          <li>
            <p>{{ item.c }}</p>
          </li>
        </ul>
      </div>
      <div class="table titlefont13" v-if="oneVerbList2 != 0">
        <ul class="title" :style="{ background: colorList[active - 1] }">
          <li><p>人称</p></li>
          <li>
            <p>陈述式过去未完成时</p>
            <!-- <p>indicativo Presente</p> -->
          </li>
          <li>
            <p>陈述式过去完成时</p>
            <!-- <p>Indicativo Perfecto</p> -->
          </li>
        </ul>
        <ul v-for="(item, index) in oneVerbList2" :key="index">
          <li>
            <p>{{ item.a }}</p>
          </li>
          <li>
            <p>{{ item.b }}</p>
          </li>
          <li>
            <p>{{ item.c }}</p>
          </li>
        </ul>
      </div>
      <div class="table titlefont13" v-if="oneVerbList3 != 0">
        <ul class="title" :style="{ background: colorList[active - 1] }">
          <li><p>人称</p></li>
          <li>
            <p>陈述式将来未完成时</p>
            <!-- <p>indicativo Presente</p> -->
          </li>
          <li>
            <p>陈述式将来完成时</p>
            <!-- <p>Indicativo Perfecto</p> -->
          </li>
        </ul>
        <ul v-for="(item, index) in oneVerbList3" :key="index">
          <li>
            <p>{{ item.a }}</p>
          </li>
          <li>
            <p>{{ item.b }}</p>
          </li>
          <li>
            <p>{{ item.c }}</p>
          </li>
        </ul>
      </div>
      <div class="table titlefont13" v-if="oneVerbList4 != 0">
        <ul class="title" :style="{ background: colorList[active - 1] }">
          <li><p>人称</p></li>
          <li>
            <p>陈述式简单过去时</p>
            <!-- <p>indicativo Presente</p> -->
          </li>
          <li>
            <p>陈述式先过去时</p>
            <!-- <p>Indicativo Perfecto</p> -->
          </li>
        </ul>
        <ul v-for="(item, index) in oneVerbList4" :key="index">
          <li>
            <p>{{ item.a }}</p>
          </li>
          <li>
            <p>{{ item.b }}</p>
          </li>
          <li>
            <p>{{ item.c }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div v-show="active == 2">
      <div class="table titlefont13" v-if="twoVerbList1 != 0">
        <ul class="title" :style="{ background: colorList[active - 1] }">
          <li><p>人称</p></li>
          <li>
            <p>虚拟式</p>
            <p>indicativo Presente</p>
          </li>
          <li>
            <p>虚拟式</p>
            <p>Indicativo Perfecto</p>
          </li>
        </ul>
        <ul v-for="(item, index) in twoVerbList1" :key="index">
          <li>
            <p>{{ item.a }}</p>
          </li>
          <li>
            <p>{{ item.b }}</p>
          </li>
          <li>
            <p>{{ item.c }}</p>
          </li>
        </ul>
      </div>
      <div class="table titlefont13" v-if="twoVerbList2.length1 != 0">
        <ul class="title" :style="{ background: colorList[active - 1] }">
          <li><p>人称</p></li>
          <li>
            <p>虚拟式现在时</p>
            <!-- <p>indicativo Presente</p> -->
          </li>
          <li>
            <p>虚拟式</p>
            <!-- <p>Indicativo Perfecto</p> -->
          </li>
        </ul>
        <ul v-for="(item, index) in twoVerbList2" :key="index">
          <li>
            <p>{{ item.a }}</p>
          </li>
          <li>
            <p>{{ item.b }}</p>
          </li>
          <li>
            <p>{{ item.c }}</p>
          </li>
        </ul>
      </div>
      <div class="table titlefont13" v-if="twoVerbList3.length1 != 0">
        <ul class="title" :style="{ background: colorList[active - 1] }">
          <li><p>人称</p></li>
          <li>
            <p>虚拟式</p>
            <!-- <p>indicativo Presente</p> -->
          </li>
          <li>
            <p>虚拟式</p>
            <!-- <p>Indicativo Perfecto</p> -->
          </li>
        </ul>
        <ul v-for="(item, index) in twoVerbList3" :key="index">
          <li>
            <p>{{ item.a }}</p>
          </li>
          <li>
            <p>{{ item.b }}</p>
          </li>
          <li>
            <p>{{ item.c }}</p>
          </li>
        </ul>
      </div>
      <div class="table titlefont13" v-show="twoVerbList4.length != 0">
        <ul class="title" :style="{ background: colorList[active - 1] }">
          <li><p>人称</p></li>
          <li>
            <p>虚拟式</p>
            <!-- <p>indicativo Presente</p> -->
          </li>
          <li>
            <p>虚拟式</p>
            <!-- <p>Indicativo Perfecto</p> -->
          </li>
        </ul>
        <ul v-for="(item, index) in twoVerbList4" :key="index">
          <li>
            <p>{{ item.a }}</p>
          </li>
          <li>
            <p>{{ item.b }}</p>
          </li>
          <li>
            <p>{{ item.c }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div v-show="active == 3">
      <div class="table titlefont13" v-if="threeVerbList1 != 0">
        <ul class="title" :style="{ background: colorList[active - 1] }">
          <li><p>人称</p></li>
          <li>
            <p>陈述式现在时</p>
            <p>indicativo Presente</p>
          </li>
          <li>
            <p>陈述现在完成时</p>
            <p>Indicativo Perfecto</p>
          </li>
        </ul>
        <ul v-for="(item, index) in threeVerbList1" :key="index">
          <li>
            <p>{{ item.a }}</p>
          </li>
          <li>
            <p>{{ item.b }}</p>
          </li>
          <li>
            <p>{{ item.c }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div v-show="active == 4">
      <div class="table titlefont13" v-if="fourVerbList1 != 0">
        <ul class="title" :style="{ background: colorList[active - 1] }">
          <li><p>人称</p></li>
          <li>
            <p>陈述式现在时</p>
            <p>indicativo Presente</p>
          </li>
          <li>
            <p>陈述现在完成时</p>
            <p>Indicativo Perfecto</p>
          </li>
        </ul>
        <ul v-for="(item, index) in fourVerbList1" :key="index">
          <li>
            <p>{{ item.a }}</p>
          </li>
          <li>
            <p>{{ item.b }}</p>
          </li>
          <li>
            <p>{{ item.c }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div v-show="active == 5">
      <div class="table titlefont13" v-if="fiveVerbList1 != 0">
        <!-- <ul class="title" :style="{ background: colorList[active - 1] }"> -->
        <!-- <li>
            <p>原形动词</p>
            <p>infinitivo</p>
          </li>
          <li>
            <p>复合原形动词</p>
            <p>infinitivo compuesto</p>
          </li>
        </ul> -->
        <ul v-for="(item, index) in fiveVerbList1" :key="index">
          <li>
            <p>{{ item.c }}</p>
          </li>
          <li>
            <!-- <p>{{ item.c }}</p> -->
            <p>{{ item.b }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeSite',
  props: ['verbObject'],
  data () {
    return {
      active: '1',
      colorList: [
        'rgba(197, 211, 250, 1)',
        'rgba(247, 203, 143, 1)',
        'rgba(254, 251, 175, 1)',
        'rgba(179, 247, 252, 1)',
        'rgba(186, 249, 202, 1)'
      ],
      oneVerbList1: [],
      oneVerbList2: [],
      oneVerbList3: [],
      oneVerbList4: [],
      twoVerbList1: [],
      twoVerbList2: [],
      twoVerbList3: [],
      twoVerbList4: [],
      threeVerbList1: [],
      threeVerbList2: [],
      threeVerbList3: [],
      threeVerbList4: [],
      fourVerbList1: [],
      fourVerbList2: [],
      fourVerbList3: [],
      fourVerbList4: [],
      fiveVerbList1: [],
      fiveVerbList2: [],
      fiveVerbList3: [],
      fiveVerbList4: [],

      // verblist: ['yo', 'tú', 'él/ella/ustedj', 'nosotro(a)s', 'vosotr(o)s', 'ellos/ellas/ustedes']
      verblist: ['yo', 'tú', 'usted', 'nosotros/as', 'vosotros/as', 'ustedes']

    }
  },
  mounted () {
    // console.log(this.verbObject)
    // if (this.verbObject) {
    // this.handledata()
    // }

  },
  methods: {
    showDes () {
      this.$emit("changeShow", 4)
    },
    handledata () {

      let verbArr = Object.entries(this.verbObject)
      // console.log(verbArr)
      let oneVerbList1 = []
      verbArr.forEach(item => {
        let lastchar = item[0].substr(-1)

        if (item[0] > 100 && item[0] < 200) {
          if (item[0] > 110 && item[0] < 120) {
            oneVerbList1[lastchar - 1] = { a: this.verblist[lastchar - 1], b: item[1] }
          }
          if (item[0] > 120 && item[0] < 130) {
            oneVerbList1[lastchar - 1].c = item[1]
          }
          if (item[0] > 130 && item[0] < 140) {
            this.oneVerbList2[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 140 && item[0] < 150) {
            this.oneVerbList2[lastchar - 1].c = item[1]
          }
          if (item[0] > 150 && item[0] < 160) {
            this.oneVerbList3[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 160 && item[0] < 170) {
            this.oneVerbList3[lastchar - 1].c = item[1]
          }
          if (item[0] > 170 && item[0] < 180) {
            this.oneVerbList4[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 180 && item[0] < 190) {
            this.oneVerbList4[lastchar - 1].c = item[1]
          }
        }
        if (item[0] > 200 && item[0] < 300) {
          if (item[0] > 210 && item[0] < 220) {
            this.twoVerbList1[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 220 && item[0] < 230) {
            this.twoVerbList1[lastchar - 1].c = item[1]
          }
          if (item[0] > 230 && item[0] < 240) {
            this.twoVerbList2[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 240 && item[0] < 250) {
            this.twoVerbList2[lastchar - 1].c = item[1]
          }
          if (item[0] > 250 && item[0] < 260) {
            this.twoVerbList3[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 260 && item[0] < 270) {
            this.twoVerbList3[lastchar - 1].c = item[1]
          }
          if (item[0] > 270 && item[0] < 280) {
            this.twoVerbList4[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 280 && item[0] < 290) {
            this.twoVerbList4[lastchar - 1].c = item[1]
          }

        }
        if (item[0] > 300 && item[0] < 400) {
          if (item[0] > 310 && item[0] < 320) {
            this.threeVerbList1[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 320 && item[0] < 330) {
            this.threeVerbList1[lastchar - 1].c = item[1]
          }
          if (item[0] > 330 && item[0] < 340) {
            this.threeVerbList2[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 340 && item[0] < 350) {
            this.threeVerbList2[lastchar - 1].c = item[1]
          }
          if (item[0] > 350 && item[0] < 360) {
            this.threeVerbList3[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 360 && item[0] < 370) {
            this.threeVerbList3[lastchar - 1].c = item[1]
          }
          if (item[0] > 370 && item[0] < 380) {
            this.threeVerbList4[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 380 && item[0] < 390) {
            this.threeVerbList4[lastchar - 1].c = item[1]
          }

        }
        if (item[0] > 400 && item[0] < 500) {
          if (item[0] > 410 && item[0] < 420) {
            this.fourVerbList1[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 420 && item[0] < 430) {
            this.fourVerbList1[lastchar - 1].c = item[1]
          }
          if (item[0] > 430 && item[0] < 440) {
            this.fourVerbList2[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 440 && item[0] < 450) {
            this.fourVerbList2[lastchar - 1].c = item[1]
          }
          if (item[0] > 450 && item[0] < 460) {
            this.fourVerbList3[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 460 && item[0] < 470) {
            this.fourVerbList3[lastchar - 1].c = item[1]
          }
          if (item[0] > 470 && item[0] < 480) {
            this.fourVerbList4[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          }
          if (item[0] > 480 && item[0] < 490) {
            this.fourVerbList4[lastchar - 1].c = item[1]
          }

        }
        if (item[0] > 500 && item[0] < 600) {
          // if (item[0] == '510') {
          //   this.fiveVerbList1[0] = { 'a': item[1], 'b': '' }
          //   this.fiveVerbList1[1] = { 'a': item[1], 'b': '' }
          //   this.fiveVerbList1[2] = { 'a': item[1], 'b': '' }
          //   this.fiveVerbList1[3] = { 'a': item[1], 'b': '' }
          // }
          if (item[0] == '510') {
            // this.fiveVerbList1[0].b = item[1]
            // this.fiveVerbList1[0].c = '原形动词infinitivo'
            this.fiveVerbList1[0] = { 'b': item[1], 'c': '原形动词infinitivo' }
          }
          if (item[0] == '520') {
            // this.fiveVerbList1[1].b = item[1]
            // this.fiveVerbList1[1].c = '复合原形动词compuesto'
            this.fiveVerbList1[1] = { 'b': item[1], 'c': '复合原形动词compuesto' }
          }
          if (item[0] == '530') {
            // this.fiveVerbList1[2].b = item[1]
            // this.fiveVerbList1[2].c = '副动词Gerundio'
            this.fiveVerbList1[2] = { 'b': item[1], 'c': '副动词Gerundio' }
          }
          if (item[0] == '540') {
            // this.fiveVerbList1[3].b = item[1]
            // this.fiveVerbList1[3].c = '复合副动词Gerundio compuesto'
            this.fiveVerbList1[3] = { 'b': item[1], 'c': '复合副动词Gerundio compuesto' }
          }
          if (item[0] == '550') {
            // this.fiveVerbList1[4].b = item[1]
            // this.fiveVerbList1[4].c = '过去分词Participio pasivo'
            this.fiveVerbList1[4] = { 'b': item[1], 'c': '过去分词Participio pasivo' }
          }
          // if (item[0] > 510 && item[0] < 520) {
          //   this.fiveVerbList1[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          // }
          // if (item[0] > 520 && item[0] < 530) {
          //   this.fiveVerbList1[lastchar - 1].c = item[1]
          // }
          // if (item[0] > 530 && item[0] < 540) {
          //   this.fiveVerbList2[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          // }
          // if (item[0] > 540 && item[0] < 550) {
          //   this.fiveVerbList2[lastchar - 1].c = item[1]
          // }
          // if (item[0] > 550 && item[0] < 560) {
          //   this.fiveVerbList3[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          // }
          // if (item[0] > 560 && item[0] < 570) {
          //   this.fiveVerbList3[lastchar - 1].c = item[1]
          // }
          // if (item[0] > 570 && item[0] < 580) {
          //   this.fiveVerbList4[lastchar - 1] = { 'a': this.verblist[lastchar - 1], 'b': item[1] }
          // }
          // if (item[0] > 580 && item[0] < 590) {
          //   this.fiveVerbList4[lastchar - 1].c = item[1]
          // }

        }

      })
      this.oneVerbList1 = JSON.parse(JSON.stringify(oneVerbList1))
    },
    handleclick (e) {
      this.active = e.target.dataset.id
      // console.log(e, 'eee')

    }

  },
  watch: {
    verbObject (newVal, oldVal) {
      this.handledata()
    }
  }

}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .illustrate {
    margin-top: 20px;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
.change-title {
  display: flex;
  padding-top: 22px;
  li {
    // width: 60px;
    display: block;
    line-height: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    // background: rgba(197, 211, 250, 1);
    // background: linear-gradient(
    //   to right,
    //   rgba(247, 203, 143, 1),
    //   rgba(255, 255, 255, 1)
    // );
    margin-right: 20px;
    border: 1px solid rgba(238, 238, 238, 1);
    padding: 5px 14px 5px 14px;
  }
  // .title {
  //   background: rgba(255, 255, 255, 1);
  // }
  .active {
    background: rgba(67, 207, 124, 1);
    color: rgba(255, 255, 255, 1);
  }
}
.table {
  width: 100%;
  border-radius: 12px;

  border: 2px solid rgba(204, 204, 204, 1);
  margin-top: 20px;
  ul {
    height: 89.51;
    display: flex;
    background: rgba(245, 245, 245, 1);
  }
  ul:not(:last-child) {
    border-bottom: 2px solid rgba(204, 204, 204, 1);
  }
  ul:nth-child(even) {
    background: rgba(255, 255, 255, 1);
  }
  ul:last-child {
    border-radius: 0 0 9px 9px;
  }
  .title {
    height: 93px;
    border-radius: 9px 9px 0 0;
    // background: rgba(195, 212, 249, 1);
  }
  li {
    width: 33.3%;
    height: 93px;
    text-align: center;
    line-height: 26.52px;
    font-weight: 500;
    // vertical-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    display: flex;
  }
}
</style>
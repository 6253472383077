<template>
  <div class="search">
    <div class="content">
      <div class="searchbox">
        <el-autocomplete
          placeholder="请输入您要翻译的单词"
          v-model="word"
          class="inline-input"
          :fetch-suggestions="querySearch"
          :trigger-on-focus="false"
          @select="beginSearch"
          :popper-append-to-body="false"
          @click.stop.native
          @keyup.enter.native="beginSearch()"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
          <template slot-scope="{ item }">
            <div class="wordlist">
              <span
                class="word"
                v-html="brightenKeyword(item.word, word)"
              ></span
              ><span class="meaning"
                >&nbsp;&nbsp;&nbsp;&nbsp;{{ item.meaning }}</span
              >
            </div>
          </template> </el-autocomplete
        ><el-button type="success" @click.stop="beginSearch()">查词</el-button>
      </div>
      <div class="searchcontent">
        <div class="tab-top">
          <div
            :class="
              activeitem == 1 ? 'tab-top-item active-item' : 'tab-top-item'
            "
            @click="activeitem = 1"
          >
            <a class="titlefont2" href="#">释义</a>
          </div>
          <div
            v-show="!isgoogle"
            :class="
              activeitem == 2 ? 'tab-top-item active-item' : 'tab-top-item'
            "
            @click="activeitem = 2"
          >
            <a class="titlefont2 no">语料库</a
            ><img src="./images/diamond.png" alt="" />
          </div>
          <div
            v-if="!isgoogle && isVerb"
            :class="
              activeitem == 3 ? 'tab-top-item active-item' : 'tab-top-item'
            "
            @click="getVerbChange"
          >
            <a class="titlefont2" href="#">动词变位</a>
          </div>
          <div
            v-if="activeitem == 4"
            :class="
              activeitem == 4 ? 'tab-top-item active-item' : 'tab-top-item'
            "
          >
            <a class="titlefont2" href="#">变位说明</a>
          </div>
        </div>
        <div class="tab-content">
          <div v-if="isgoogle">
            <GoogleSearch :isgoogle="isgoogle" :word="transword"></GoogleSearch>
          </div>
          <div v-else>
            <Explain
              :word="transword"
              :meaning="meaning"
              :isVerb="isVerb"
              :gradeVo="gradeVo"
              v-show="activeitem == 1"
              @changeword="beginchangeword"
              :isChinese="isChinese"
              @changeCreenWord="changeCreenWord"
            ></Explain>
            <Corpus
              :word="transword"
              v-show="activeitem == 2"
              @changeword="beginchangeword"
              @changeCreenWord="changeCreenWord"
              :isChinese="isChinese"
            ></Corpus>
            <ChangeSite
              v-show="activeitem == 3"
              :verbObject="verbObject"
              @changeShow="changeShow"
            ></ChangeSite>
            <ChangeSiteDes v-show="activeitem == 4"></ChangeSiteDes>
          </div>
        </div>
      </div>
    </div>
    <el-backtop :bottom="100">
      <div
        style="height: 100%; width: 100%; background-color: rgba(0, 0, 0, 0)"
      >
        <img src="./images/up.png" alt="" />
      </div>
    </el-backtop>
    <!-- 屏幕取词修改为对话框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="40%"
      @close="closedialog"
      :show-close="false"
    >
      <div class="sketch_content">
        <div>
          <p class="titlefont11 dinRegular">
            {{ selectarr.word || tempcreenword }}
          </p>
        </div>
        <div style="margin-top: 20px">
          <p class="font18400 grey1">
            {{ selectarr.meaning }}
          </p>
        </div>
        <div style="margin-top: 20px" class="titlebox">
          <p
            @click="
              detailShiyiNew(
                selectarr.origin || selectarr.word || tempcreenword
              )
            "
            class="titlefont1 cursorpointer"
          >
            详细释义
          </p>
        </div>
        <Explain
          v-show="showmore"
          :word="creenword"
          :meaning="creenmeaning"
          :isVerb="creenisVerb"
          :gradeVo="creengradeVo"
          isDialog="true"
          :isChinese="isChinese"
        ></Explain>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Explain from "./explain"
import GoogleSearch from "./googleSearch"
import Corpus from "./corpus"
import ChangeSite from "./changeSite"
import ChangeSiteDes from "./changeSiteDes"
import {
  Search, wordDetail, verbChange, spellError, screenQuery
} from "@/api"
export default {
  name: 'Search',
  components: { Explain, Corpus, ChangeSite, ChangeSiteDes, GoogleSearch },
  created () {


  },
  mounted () {
    if (this.$route.params.word) {
      // console.log(this.$route.params.word, 'this.$route.params.word')
      this.isgoogle = this.$route.params.isgoogle
      this.word = this.transword = this.$route.params.word ? this.$route.params.word : ''
      this.isChinese = this.word.charCodeAt(0) > 255
      // this.activeitem = this.$route.params.select ? this.$route.params.select : '1'

      if (!this.isgoogle) this.beginSearch()
    }
    // let csword = "hacer"
    // let isc = csword.charCodeAt(0) > 255
    // console.log(isc, 'this.isChinese')

  },
  data () {
    return {
      dialogVisible: false,//释义详情 修改为对话框
      isgoogle: false,//是否在线搜索
      isChinese: false,
      activeitem: 1,
      word: '',
      meaning: '',
      transword: '',
      suggestions: [],
      isVerb: false,//是否动词
      gradeVo: {},
      verbObject: {},
      selectarr: [],//2024510修改功能 屏幕取词
      creenword: '',
      showmore: false,
      creenmeaning: '',
      creenisVerb: false,//是否动词
      creengradeVo: {},
      tempcreenword: ""

    }
  },
  methods: {
    closedialog (done) {
      this.showmore = false
      this.creenword = ''
      this.selectarr = []
      this.creenmeaning = ''
      this.creengradeVo = {}
      this.tempcreenword = ''
      done

    },
    changeCreenWord (w) {
      this.tempcreenword = w
      this.dialogVisible = true
      screenQuery({ word: w }).then((d) => {
        // console.log(d, 'ddd')
        if (d.code == 200) {
          if (d.data) {
            // this.isShowShiYiBox = true
            this.selectarr = d.data

          }

        } else {
          this.$message.error(d.msg)
        }
      })
    },
    detailShiyiNew (w) {
      // console.log(w)
      this.showmore = !this.showmore
      if (this.creenword != w) {
        this.getWordDetailNew(w)
        this.creenword = w
      }


    },
    changeShow (val) {
      this.activeitem = val
    },
    beginchangeword (parmword) {
      this.word = parmword
      this.activeitem = 1
      this.isChinese = this.word.charCodeAt(0) > 255
      // 调用函数
      this.scrollToTop()
      // console.log(this.activeitem, 'this.activeitem')
      this.beginSearch()
    },
    // 滚动到页面顶部的函数
    scrollToTop () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth' // 可选，平滑滚动效果
      })
    },


    querySearch (queryString, cb) {
      this.suggestions = []
      // var restaurants = this.restaurants
      // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      this.isChinese = queryString.charCodeAt(0) > 255
      //  && this.activeitem == 1
      if (queryString) {
        Search({ keyword: queryString }).then((d) => {
          // console.log(d, 'ddd')
          this.suggestions = d.data

          if (d.data.length == 0) {
            spellError({ word: queryString }).then((d) => {
              this.suggestions = [{ isnet: true, word: '在线搜索', meaning: queryString }]
              this.suggestions = [...this.suggestions, ...d.data]
              // console.log(d.data, 'd.data')

              cb(this.suggestions)
            })

          } else {
            cb(this.suggestions)
          }

          // 调用 callback 返回建议列表的数据

        })
      } else {
        cb(this.suggestions)
      }

    },
    beginSearch (item) {
      // console.log(item, 'item')
      this.isgoogle = false
      if (this.activeitem == 3) this.activeitem = 1
      this.verbObject = {}

      if (item && item.word) {
        if (item.isnet) {
          //在线搜索接口
          this.isgoogle = true
          this.word = item.meaning
          this.transword = item.meaning

          // this.googleSearch()

        } else {
          this.transword = item.word
          this.word = item.word
          this.getWordDetail()
        }



      } else {
        this.transword = this.word
        this.getWordDetail()

      }



    },

    brightenKeyword (val, keyword) {
      const Reg = new RegExp(keyword, "gi")
      if (val) {
        return val.replace(
          Reg,
          `<span style="color: rgba(67, 207, 124, 1);
">${keyword}</span>`
        )
      }
    },
    getWordDetail () {
      // console.log(this.word, 'getWordDetail')
      wordDetail({ word: this.word }).then((d) => {
        if (d.code == 200) {
          this.meaning = d.data.meaning
          this.isVerb = d.data && d.data.isVerb
          this.gradeVo = d.data && d.data.gradeVo
        }

      })

    },
    getWordDetailNew (w) {
      wordDetail({ word: w }).then((d) => {
        if (d.code == 200) {
          this.creenmeaning = d.data.meaning
          this.creenisVerb = d.data && d.data.isVerb
          this.creengradeVo = d.data && d.data.gradeVo
        }

      })

    },
    //动词变位
    getVerbChange (type) {
      this.activeitem = 3
      if (JSON.stringify(this.verbObject) == '{}') {
        verbChange({ word: this.word }).then((d) => {
          // console.log(d, 'sdfsf')
          if (d.code == 200) {
            this.verbObject = d.data
          }
        })
      }
    },
    //语料

    //解释
  },


}
</script>

<style lang="less" scoped>
@import url(../../assets/css/common.css);

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #eee;
}
/*定义滑块 样式*/
::-webkit-scrollbar-thumb {
  height: 100px; /* 滚动条滑块长度 */
  background-color: #ccc;
}
.sketch_content {
  max-height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
}
// 滚动条的样式,宽高分别对应横竖滚动条的尺寸
.sketch_content::-webkit-scrollbar {
  width: 3px;
  background: #eee;
}

// 滚动条里面默认的小方块,自定义样式
.sketch_content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 2px;
}

// 滚动条里面的轨道
.sketch_content::-webkit-scrollbar-track {
  background: transparent;
}
/deep/.el-dialog__header {
  padding: 0;
}

/deep/.el-dialog {
  max-height: 50vh;
  // overflow-y: auto;
  opacity: 1;
  // background: rgba(255, 255, 255, 0.8);

  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 12px;

  box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
  backdrop-filter: blur(15px);
}

/deep/.el-backtop {
  background-color: transparent;
  box-shadow: none;
}
/deep/.el-autocomplete {
  width: 88.6%;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.4);
}
/deep/.el-autocomplete-suggestion {
  left: 0 !important;
  // top: 411px;
  // width: 696px !important;
  height: 419px;
  opacity: 1;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
  text-align: left;
  overflow-x: hidden;
  padding: 0 20px;
}
/deep/.el-autocomplete-suggestion__wrap {
  max-height: 419px;
}
/deep/.el-autocomplete-suggestion li {
  padding: 10px;
}
/deep/.el-autocomplete-suggestion li:hover {
  width: 100%;
  // height: 50px;
  padding: 10px;
  opacity: 1;
  border-radius: 12px;
  background: rgba(245, 245, 247, 1);
}
/deep/.el-popper .popper__arrow {
  border: none !important;
}
/deep/.el-popper {
  margin-top: 6px;
}
/deep/.el-input__inner {
  height: 60px;
  border: 2px solid rgba(255, 255, 255, 1);
  backdrop-filter: blur(20px);
  border-radius: 12px;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 26.52px;
  color: rgba(29, 29, 31, 1);
}
/deep/.el-input--prefix .el-input__inner {
  padding-left: 4rem;
}
/deep/.el-button {
  width: 10.3%;
}
/deep/.el-input__inner:hover {
  border-color: #fff;
}
/deep/.el-input__inner:blur {
  border-color: #fff;
}
/deep/.el-icon-search:before {
  content: "";
  background: url("../../assets/img/search/search.png") no-repeat;
  display: block;
  width: 24px;
  height: 24px;
  line-height: 3.75rem;
  background-size: 100%;
  margin-left: 1.25rem;
}
/deep/.el-input__prefix {
  top: 18px;
}

//按钮
/deep/.el-button--success {
  width: 10%;
  max-width: 117px;
  min-width: 80px;
  height: 60px;
  opacity: 1;
  border-radius: 12px;
  background: rgba(67, 207, 124, 1);
  border-color: rgba(67, 207, 124, 1);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
}
.titlebox {
  height: 21px;
  border-left: 3px solid rgba(67, 207, 124, 1);
  line-height: 21px;
  padding-left: 7px;
}

//选项卡

/deep/.el-tabs__content {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 1);
  padding: 0 42px;
}
.search {
  width: 100%;
  // height: 100%;
  // min-height: 100vh;
  padding-top: 2rem;

  .content {
    // width: 58.55%;
    width: 90%;
    max-width: 1122px;
    // height: 300px;
    margin: 0 auto;
    // background-color: #fff;
    .searchbox {
      margin-top: 6.125rem;
      display: flex;
      justify-content: space-between;
    }
    .searchcontent {
      margin-top: 1.25rem;
      padding-bottom: 100px;
      // margin-bottom: 30px;
      .tab-top {
        display: flex;

        .tab-top-item {
          width: 134px;
          height: 50px;
          cursor: pointer;
          border-radius: 12px 12px 0px 0px;
          background: rgba(255, 255, 255, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 6px;
          img {
            width: 24px;
            height: 24px;
          }
        }
        .active-item {
          background-color: rgba(255, 255, 255, 0.6);
          border: 2px solid #ffffff;
          border-bottom: none;
        }
      }
      .tab-content {
        width: 100%;
        max-width: 1122px;
        // height: 1186px;
        min-height: 90vh;
        padding: 0 45px;
        opacity: 1;
        background: rgba(255, 255, 255, 0.6);

        border: 2px solid rgba(255, 255, 255, 1);
        border-radius: 0px 12px 12px 12px;
        backdrop-filter: blur(20px);
      }
    }
  }
}
</style>
<template>
  <div class="dinRegular">
    <div class="title-box">
      <div class="illustrate titlefont14"><p>变位说明</p></div>
      <div class="font16400 black1 des">
        <p class="font18400">一、陈述式现在时</p>
        <p>1.规则变位动词</p>
        <p>1)以-ar结尾的第一变位动词，去掉原形动词的词尾-ar, 分别加以下词尾:</p>
      </div>
    </div>

    <div class="table titlefont13">
      <ul class="title" style="background: #c5d3fa">
        <li><p>人称</p></li>
        <li>
          <p>词尾：-ar</p>
        </li>
        <li>
          <p>例词：habiar</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Yo</p>
        </li>
        <li>
          <p>-o</p>
        </li>
        <li>
          <p>hablo</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Tú</p>
        </li>
        <li>
          <p>-as</p>
        </li>
        <li>
          <p>hablas</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Él,ella,usted</p>
        </li>
        <li>
          <p>-a</p>
        </li>
        <li>
          <p>habla</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Nosotros,nosotras</p>
        </li>
        <li>
          <p>-amos</p>
        </li>
        <li>
          <p>hablamos</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Vosotros,vosotras</p>
        </li>
        <li>
          <p>-áis</p>
        </li>
        <li>
          <p>habláis</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>ellos,ellas,ustedes</p>
        </li>
        <li>
          <p>-an</p>
        </li>
        <li>
          <p>hablan</p>
        </li>
      </ul>
    </div>
    <div class="font16400 black1 des">
      <p>2)以-er结尾的第二变位动词，去掉原形动词的词尾-er，分别加以下词尾：</p>
    </div>
    <div class="table titlefont13" style="margin-bottom: 40px">
      <ul class="title" style="background: #c5d3fa">
        <li><p>人称</p></li>
        <li>
          <p>词尾：-er</p>
        </li>
        <li>
          <p>例词：comer</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Yo</p>
        </li>
        <li>
          <p>-o</p>
        </li>
        <li>
          <p>como</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Tú</p>
        </li>
        <li>
          <p>-es</p>
        </li>
        <li>
          <p>comes</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Él,ella,usted</p>
        </li>
        <li>
          <p>-e</p>
        </li>
        <li>
          <p>come</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Nosotros,nosotras</p>
        </li>
        <li>
          <p>-amos</p>
        </li>
        <li>
          <p>comemos</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Vosotros,vosotras</p>
        </li>
        <li>
          <p>-éis</p>
        </li>
        <li>
          <p>coméis</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>ellos,ellas,ustedes</p>
        </li>
        <li>
          <p>-en</p>
        </li>
        <li>
          <p>comen</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeSiteDes',
}
</script>

<style lang="less" scoped>
.title-box {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  .illustrate {
    margin-top: 20px;
    // text-decoration-line: underline;
    cursor: pointer;
    padding-left: 5px;
    border-left: 3px solid rgba(67, 207, 124, 1);
  }
}
.des {
  margin-top: 20px;
  line-height: 2;
  font-family: "MiSans";
}
.change-title {
  display: flex;
  padding-top: 22px;
  li {
    // width: 60px;
    display: block;
    line-height: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    // background: rgba(197, 211, 250, 1);
    // background: linear-gradient(
    //   to right,
    //   rgba(247, 203, 143, 1),
    //   rgba(255, 255, 255, 1)
    // );
    margin-right: 20px;
    border: 1px solid rgba(238, 238, 238, 1);
    padding: 5px 14px 5px 14px;
  }
  // .title {
  //   background: rgba(255, 255, 255, 1);
  // }
  .active {
    background: rgba(67, 207, 124, 1);
    color: rgba(255, 255, 255, 1);
  }
}
.table {
  width: 100%;
  border-radius: 12px;

  border: 2px solid rgba(204, 204, 204, 1);
  margin-top: 20px;
  ul {
    height: 89.51;
    display: flex;
    background: rgba(245, 245, 245, 1);
  }
  ul:not(:last-child) {
    border-bottom: 2px solid rgba(204, 204, 204, 1);
  }
  ul:nth-child(even) {
    background: rgba(255, 255, 255, 1);
  }
  ul:last-child {
    border-radius: 0 0 9px 9px;
  }
  .title {
    height: 93px;
    border-radius: 9px 9px 0 0;
    // background: rgba(195, 212, 249, 1);
  }
  li {
    width: 33.3%;
    height: 93px;
    text-align: center;
    line-height: 26.52px;

    // vertical-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-weight: 500;
    }
  }
  .title {
    display: flex;
  }
}
</style>
<template>
  <div class="explainbox">
    <div class="shiyi_item bottom1" id="shiyi_one">
      <div class="top_item">
        <p class="titlefont11 dinRegular">{{ word }}</p>
      </div>
      <div class="top_item">
        <!-- <p class="font14400 black1 explain" v-if="meaningarr.length > 1">
          <span class="grey1">{{ meaningarr[0] }}. </span>
          {{ meaningarr[1] }}
        </p> -->
        <p class="font14400 black1 explain">{{ meaning }}</p>
        <!-- <p
          class="font14400 black1 explain"
          v-for="(item, index) in googleResult"
          :key="index"
        >
          {{ item }}
        </p> -->
      </div>
      <div class="top_item beizhufont2">
        <ul class="toplist">
          <li v-show="gradeVo.deleGrade">{{ gradeVo.deleGrade }}</li>
          <li v-show="gradeVo.proGrade">{{ gradeVo.proGrade }}</li>
        </ul>
      </div>
    </div>
    <div class="shiyi_three" id="shiyi_three">
      <div class="shiyi_item bottom1" id="shiyi_two">
        <div class="titlebox">
          <p class="titlefont1">更多释义</p>
        </div>
        <ul class="explainlist">
          <li
            v-for="(item, index) in explain"
            :key="'li' + index"
            v-show="item.val.length > 0"
            @click="
              activeExplain == explainchlist[item.keys].act
                ? (activeExplain = 0)
                : (activeExplain = explainchlist[item.keys].act)
            "
          >
            <p
              :class="
                activeExplain == explainchlist[item.keys].act
                  ? 'titlefont2 active-explain'
                  : 'titlefont2'
              "
            >
              {{ explainchlist[item.keys].ch }}
            </p>
            <img
              v-show="activeExplain == explainchlist[item.keys].act"
              src="../images/down-active.png"
              alt=""
            />
            <img
              v-show="activeExplain != explainchlist[item.keys].act"
              src="../images/down.png"
              alt=""
            />
          </li>
        </ul>

        <div
          class="shiyicontent zhengwen11 dinRegular"
          v-for="(item2, index2) in explain"
          :key="'one' + index2"
          v-show="explainlist[activeExplain] == item2.keys"
        >
          <div v-for="(item3, index3) in item2.val" :key="'two' + index3">
            <p
              class="green1"
              style="margin-bottom: 5px"
              v-if="item3.specialtype"
            >
              {{ item3.specialtype }}
            </p>
            <p class="grey2">
              {{ item3.origin }}
            </p>
            <div
              v-for="(item1, index1) in item3.natureDetailList"
              :key="'three' + index1"
            >
              <p>
                {{ item1.nature }}
              </p>
              <p
                v-for="(item, index) in item1.dictDetailList"
                :key="'four' + index"
              >
                <span v-if="item1.dictDetailList.length > 1"
                  >{{ index + 1 }}.</span
                >
                <span>{{ item.subject }}&nbsp;</span>
                <span>{{ item.region }}&nbsp;</span>
                <span
                  class="cursorpointer"
                  @click="getShiYiNew($event)"
                  @mouseover.stop="setColor($event)"
                  @mouseout.stop="delColor($event)"
                  v-html="addElementSpan(item.meaning) + '<br />'"
                ></span>
                <span
                  class="cursorpointer"
                  @click="getShiYiNew($event)"
                  @mouseover.stop="setColor($event)"
                  @mouseout.stop="delColor($event)"
                  v-html="addElementSpan(item.using)"
                ></span>
                <span v-if="item.synonym"
                  ><br />SINÓNIMO&nbsp;&nbsp;
                  <span
                    class="cursorpointer"
                    @click="getShiYiNew($event)"
                    @mouseover.stop="setColor($event)"
                    @mouseout.stop="delColor($event)"
                    v-html="addElementSpan(item.synonym)"
                  ></span>
                </span>
                <span v-if="item.antonym"
                  ><br />ANTÓNIMO&nbsp;&nbsp;
                  <span
                    class="cursorpointer"
                    @click="getShiYiNew($event)"
                    @mouseover.stop="setColor($event)"
                    @mouseout.stop="delColor($event)"
                    v-html="addElementSpan(item.antonym)"
                  ></span
                ></span>
              </p>
            </div>
            <p class="grey2" v-if="item3.wordGroup">
              {{ item3.wordGroup }}
            </p>
            <div
              v-for="(item1, index) in item3.phraseDetailList"
              :key="'three3' + index"
            >
              <span>{{ item1.name }}&nbsp;</span>
              <span>{{ item1.subject }}&nbsp;</span>
              <span>{{ item1.typeWriting }}&nbsp;</span>

              <p
                v-for="(item, index) in item1.phraseMeaningList"
                :key="'four4' + index"
              >
                <span v-if="item1.phraseMeaningList.length > 1"
                  >{{ index + 1 }}.</span
                >
                <!-- <span>{{ item.subject }}&nbsp;</span>
              <span>{{ item.name }}<br /></span> -->
                <span v-if="item.subject">{{ item.subject }}&nbsp;</span>
                <span v-if="item.region">{{ item.region }}&nbsp;</span>
                <span
                  class="cursorpointer"
                  @click="getShiYiNew($event)"
                  @mouseover.stop="setColor($event)"
                  @mouseout.stop="delColor($event)"
                  v-html="addElementSpan(item.meaning) + '<br />'"
                ></span>
                <span
                  class="cursorpointer"
                  @click="getShiYiNew($event)"
                  @mouseover.stop="setColor($event)"
                  @mouseout.stop="delColor($event)"
                  v-html="addElementSpan(item.using)"
                ></span>
                <span
                  class="cursorpointer"
                  @click="getShiYiNew($event)"
                  @mouseover.stop="setColor($event)"
                  @mouseout.stop="delColor($event)"
                  v-html="addElementSpan(item.example)"
                ></span>
                <span v-if="item.synonym"
                  ><br />SINÓNIMO&nbsp;&nbsp;
                  <span
                    class="cursorpointer"
                    @click="getShiYiNew($event)"
                    @mouseover.stop="setColor($event)"
                    @mouseout.stop="delColor($event)"
                    v-html="addElementSpan(item.synonym)"
                  ></span>
                </span>
                <span v-if="item.antonym"
                  ><br />ANTÓNIMO&nbsp;&nbsp;
                  <span
                    class="cursorpointer"
                    @click="getShiYiNew($event)"
                    @mouseover.stop="setColor($event)"
                    @mouseout.stop="delColor($event)"
                    v-html="addElementSpan(item.antonym)"
                  ></span
                ></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="shiyi_item">
        <div class="titlebox readingone">
          <p class="titlefont1">经典读物例句</p>
        </div>
        <div class="beizhufont1 readingtwo">
          <p>共{{ exampleAll }}条结果</p>
        </div>
        <ul class="readingthree">
          <li v-for="(item, index) in exampleList" :key="index">
            <p class="zhengwen1 explain1">
              <!-- v-html="brightenKeyword(item.example, word)" -->
              {{
                (examplequery.pageNum - 1) * examplequery.pageSize + index + 1
              }}.
              <span
                @click="getShiYiNew($event)"
                :class="isChinese ? 'zhengwen2' : 'dinRegular cursorpointer'"
                @mouseover.stop="setColor($event)"
                @mouseout.stop="delColor($event)"
                v-html="brightenKeyword(addElementSpan(item.example), word)"
              ></span>
            </p>
            <p
              @click="getShiYiNew($event)"
              :class="
                isChinese
                  ? 'dinRegular cursorpointer explain2'
                  : 'zhengwen2 explain2'
              "
              @mouseover.stop="setColor($event)"
              @mouseout.stop="delColor($event)"
              v-html="brightenKeyword(addElementSpan(item.meaning), word)"
            ></p>
          </li>
          <li>
            <el-pagination
              :page-size="examplequery.pageSize"
              :current-page="examplequery.pageNum"
              hide-on-single-page
              background
              layout="prev, pager, next"
              :total="exampleAll"
              prev-text="上一页"
              next-text="下一页"
              @current-change="changePage"
            >
            </el-pagination>
          </li>
        </ul>
      </div>
      <!-- //mobilebox -->
      <div
        class="mobilebox"
        v-show="isShowShiYiBox"
        :style="{ left: mouseX + 'px', top: mouseY + 'px' }"
      >
        <div class="shiyititle">
          <p class="font20500 black1" v-if="!isShezhi">{{ selectarr.word }}</p>
          <p class="font20500 black1" v-if="isShezhi">设置</p>
          <div class="buttonlist">
            <img
              style="margin-right: 10px"
              src="./images/shezhi.png"
              alt=""
              @click="isShezhi = !isShezhi"
            /><img src="./images/close.png" alt="" @click="closeMobileBoxis" />
          </div>
        </div>
        <div class="shiyicontent" v-if="!isShezhi">
          <p class="font18400 grey1">
            {{ selectarr.meaning }}
          </p>
        </div>
        <div class="shiyibottm" v-if="!isShezhi">
          <p
            class="font16400 green1 cursorpointer"
            @click="detailShiyi(selectarr.origin)"
          >
            详细释义
          </p>
        </div>
        <div class="shiyibottm shiyibottm2" v-if="isShezhi">
          <p class="font16400 grey1">开启屏幕取词</p>
          <el-switch v-model="canGetWord" active-color="#1890FF"> </el-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  moreExplain, searchExample, wordDetail, screenQuery
} from "@/api"
export default {
  name: "Explain",
  props: ['word', 'meaning', 'gradeVo', 'isVerb', 'isChinese'],
  mounted () {
    if (this.word) {
      this.examplequery.word = this.word
      this.examplequery.pageNum = 1
      // vp_ad:动词搭配词典 

      // sp_ch: 西汉词典
      // sp_sp: 西西词典
      // "sp_ch_et": "西汉经贸词典"
      // "sp_ch_science": "西汉科技工程词典"

      // ch_sp: 汉西词典
      // "ch_sp_et": "汉西经贸词典"
      // "ch_sp_science": "汉西科技工程词典"
      this.explain = []
      // this.isChinese = this.word.charCodeAt(0) > 255
      if (this.isChinese) {


        this.getExplain('ch_sp')
        this.getExplain('vp_ad')
        this.getExplain('ch_sp_et')
        this.getExplain('ch_sp_science')

      } else {

        this.getExplain('sp_ch')
        this.getExplain('sp_sp')
        this.getExplain('vp_ad')

        this.getExplain('sp_ch_et')
        this.getExplain('sp_ch_science')

      }



      this.getJuzi()
      this.isShowShiYiBox = false
      // this.meaningarr = this.meaning.split(".")
      // console.log(this.meaningarr, 'this.meaningarr')
    }

  },
  data () {
    return {
      // isChinese: false,
      activeExplain: 0,
      meaningarr: [],

      // explain_sp_ch: {},//西汉词典
      // explain_sp_sp: {},//西西词典
      // explain_vp_ad: {},//动词搭配
      // explain_ch_sp: {},//汉西词典
      // explain_special_one: {},//专业词典
      // explain_special_two: {},//专业词典
      explain: [
      ],
      explainlist: {
        '1': 'sp_ch',
        '2': 'sp_sp',
        '3': 'special',
        '4': 'vp_ad',
        '5': 'ch_sp'
      },
      explainchlist: {
        'sp_ch': { 'ch': '西汉词典', 'act': 1 },
        'sp_sp': { 'ch': '西西词典', 'act': 2 },
        'special': { 'ch': '专业词典', 'act': 3 },
        'vp_ad': { 'ch': '动词搭配词典', 'act': 4 },
        'ch_sp': { 'ch': '汉西词典', 'act': 5 }
      },
      examplequery: { word: '', exampleType: 'exampleType', pageSize: 15, pageNum: 1 },

      exampleList: [],
      exampleAll: 0,

      judgeTimer: '',
      selectword: '',
      selectarr: {},
      canGetWord: true,  //是否可取词
      isShezhi: false,//是否展示设置
      isShowShiYiBox: false,//屏幕取词box
      mouseX: '',
      mouseY: '',

    }
  },
  methods: {

    detailShiyi (origin) {
      let selword = origin || this.selectword
      this.$emit("changeword", selword)
      this.isShowShiYiBox = false
    },
    closeMobileBoxis () {
      this.isShowShiYiBox = false
    },
    getShiYi (e) {
      // console.log(this.selectword, 'this.selectword')
      this.selectarr = []
      if (this.selectword.indexOf(' ') > '-1') {
        return
      }
      if (this.selectword.charCodeAt(0) > 255) {
        return
      }
      this.isShowShiYiBox = true
      let element = document.getElementById("shiyi_three")
      let rect = element.getBoundingClientRect()
      let threetop = rect.top
      let threeleft = rect.left
      this.mouseX = e.clientX - threeleft - 40
      this.mouseY = e.clientY - threetop + 10

      // if (this.isShezhi) this.isShowShiYiBox = true
      if (this.canGetWord && this.selectword) {

        // let viewportWidth = window.innerWidth || document.documentElement.clientWidth//浏览器可视区域
        // let element = document.getElementById("shiyi_one")
        // let rect = element.getBoundingClientRect()
        // // let onewidth = rect.width
        // let oneheight = rect.height
        // let elementtwo = document.getElementById("shiyi_two")
        // let recttwo = elementtwo.getBoundingClientRect()
        // // let twowidth = recttwo.width

        // let twoheight = recttwo.height
        // console.log(oneheight, twoheight, 'oneheight,twoheight')

        // 5.8


        screenQuery({ word: this.selectword }).then((d) => {
          // console.log(d, 'ddd')
          if (d.code == 200) {
            if (d.data) {

              this.selectarr = d.data
            }

          } else {
            this.$message.error(d.msg)
          }
        })

      }


    },
    //新版屏幕取词弹窗
    getShiYiNew (e) {
      this.selectarr = []
      if (this.selectword.indexOf(' ') > '-1') {
        return
      }
      if (this.selectword.charCodeAt(0) > 255) {
        return
      }
      if (this.selectword) {
        this.$emit("changeCreenWord", this.selectword)


      }

    },
    shortArticle () {
      return addElementSpan(str)
    },

    setColor (e) {

      // this.selectarr = {}
      this.selectword = e.target.innerText
      if (this.selectword.charCodeAt(0) <= 255) {
        clearTimeout(this.judgeTimer)
        if (e.target.innerText.indexOf(' ') == '-1') {

          this.judgeTimer = setTimeout(function () {

            e.target ? e.target.classList.add('wordhighlight') : ''
            this.eleClick = e
          }, 500)

        }
      }
    },
    delColor (e) {

      clearTimeout(this.judgeTimer)
      if (!this.isShowShiYiBox) {
        e.target.classList.remove('wordhighlight')
        // this.selectword = ''
      }
    },
    addElementSpan (str = '') {
      return str
        .split(' ')
        .map((item) => {
          const { start, word, end } = this.getWord(item)
          return `${start}<span>${word}</span>${end} `
          // return `<span>${item}</span> `
        })
        .join('')
    },

    getWord (str = '') {
      let word = ''
      let start = ''
      let end = ''
      let j = str.length - 1
      let i = 0

      while (i < str.length) {
        if (/^[a-zA-Z0-9ñáéíóúüÑÁÉÍÓÚ]$/.test(str[i])) {
          break
        }
        start = start + str[i]
        i += 1
      }

      while (j >= 0) {
        if (/^[a-zA-Z0-9ñáéíóúüÑÁÉÍÓÚ]$/.test(str[j])) {
          break
        }
        end = str[j] + end
        j -= 1
      }

      word = str.slice(i, j + 1)

      // 处理数字
      if (!word && start === end) {
        start = ''
      }

      return {
        start,
        word,
        end
      }
    }

    ,
    handleword (e) {
      console.log(e, 'der')
    },
    brightenKeyword (val, keyword) {
      const Reg = new RegExp(keyword, "gi")
      if (val) {
        return val.replace(
          Reg,
          `<span style="color: rgba(67, 207, 124, 1);
">${keyword}</span>`
        )
      }
    },
    getExplain (type) {
      // vp_ad:动词搭配词典 

      // sp_ch: 西汉词典
      // sp_sp: 西西词典
      // "sp_ch_et": "西汉经贸词典"
      // "sp_ch_science": "西汉科技工程词典"

      // ch_sp: 汉西词典
      // "ch_sp_et": "汉西经贸词典"
      // "ch_sp_science": "汉西科技工程词典"
      moreExplain({ word: this.word, type: type }).then((d) => {
        if (d.code == 200) {

          if (type == 'sp_ch_et' || type == 'ch_sp_et') {
            let tmpone = d.data
            if (tmpone.length > 0) tmpone[0].specialtype = "经贸"
            this.explain.push({ val: tmpone, keys: 'special' })
          } else if (type == 'sp_ch_science' || type == 'ch_sp_science') {


            let tmptwo = d.data
            if (tmptwo.length > 0) tmptwo[0].specialtype = "科技工程"
            this.explain.forEach((exitem) => {
              if (exitem.keys == 'special') {
                exitem.val = [...exitem.val, ...tmptwo]
              }
            })
          } else {
            this.explain.push({ val: d.data, keys: type })
          }

        }

      })

    },
    changePage (page) {
      this.examplequery.pageNum = page
      this.getJuzi()
    },
    getJuzi () {
      // this.meaningarr = this.meaning.split(".")
      // console.log(this.examplequery, 'this.examplequery')
      searchExample(this.examplequery).then((d) => {
        if (d.code == 200) {
          this.exampleList = d.rows
          this.exampleAll = d.total
        }

      })

    },


  },
  watch: {
    word (newVal, oldVal) {
      if (newVal) {
        this.activeExplain = 0
        this.explain = []
        // this.meaning = ""
        // this.exampleList = []
        // this.exampleAll = 0
        if (newVal.charCodeAt(0) > 255) {


          this.getExplain('ch_sp')
          this.getExplain('vp_ad')
          this.getExplain('ch_sp_et')
          this.getExplain('ch_sp_science')

        } else {

          this.getExplain('sp_ch')
          this.getExplain('sp_sp')
          this.getExplain('vp_ad')
          this.getExplain('sp_ch_et')
          this.getExplain('sp_ch_science')


        }

        this.examplequery.word = newVal
        this.examplequery.pageNum = 1
        this.getJuzi()

      }


    }
  }

}
</script>

<style lang="less" scoped>
/deep/.el-pagination {
  text-align: center;
  margin: 20px 0 40px;
}
/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .btn-prev,
/deep/.el-pagination.is-background .el-pager li {
  height: 32px;
  line-height: 32px;
  opacity: 1;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 1);
  backdrop-filter: blur(20px);

  font-size: 14px;
  font-weight: 400;
  color: rgba(119, 119, 119, 1);

  padding: 0 12px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  height: 32px;
  background: rgba(67, 207, 124, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
}
.explainbox {
  position: relative;
}
.shiyi_three {
  position: relative;
}
.mobilebox {
  width: 226px;
  // height: 183.5px;
  padding: 16px;
  opacity: 1;
  background: rgba(255, 255, 255, 0.8);

  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 12px;

  box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.25);
  backdrop-filter: blur(15px);
  position: absolute;

  .shiyititle {
    display: flex;
    justify-content: space-between;
    .buttonlist {
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
  .shiyicontent {
    margin: 10px 0;
  }
  .shiyibottm {
    height: 27px;
    width: 100%;
    border-top: 1px dashed rgba(204, 204, 204, 1);
    text-align: center;
    padding-top: 8px;
  }
  .shiyibottm2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
/deep/.wordhighlight {
  height: 24px;
  // padding: 0 2px;
  border-radius: 4px;
  background: rgba(173, 255, 206, 0.6);
}
.titlebox {
  height: 21px;
  border-left: 3px solid rgba(67, 207, 124, 1);
  line-height: 21px;
  padding-left: 7px;
}
.shiyi_item {
  padding: 30px 0;
  .top_item {
    margin-top: 10px;
    .explain {
      line-height: 24px;
    }
    .toplist {
      display: flex;
      li {
        display: block;
        height: 27px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(255, 255, 255, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4px 14px 4px 14px;
        margin-right: 16px;
      }
    }
  }
  .explainlist {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    li {
      cursor: pointer;
      // width: 112px;
      height: 36px;
      display: block;
      padding: 0px 10px;
      opacity: 1;
      border-radius: 6px 6px 0px 0px;
      background: rgba(255, 255, 255, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      img {
        width: 24px;
        height: 24px;
      }
      .active-explain {
        color: rgba(67, 207, 124, 1);
      }
    }
  }
  .shiyicontent {
    width: 100%;
    opacity: 1;
    border-radius: 0px 6px, 6px, 6px;
    background: rgba(255, 255, 255, 1);
    padding: 12px 24px;
    white-space: pre-wrap;
  }
  .readingtwo {
    margin-top: 10px;
    line-height: 23.87px;
  }
  .readingthree {
    li {
      margin-top: 16px;
      .explain2 {
        margin-top: 8px;
      }
    }
  }
}
</style>
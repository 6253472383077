<template>
  <div class="explainbox">
    <div class="shiyi_item" id="shiyi_one">
      <div class="top_item">
        <p class="titlefont14" style="margin-top: 20px">网络释义</p>
        <p class="titlefont11 dinRegular">
          {{ word }}
        </p>
      </div>
      <div class="top_item">
        <p
          class="font14400 black1 explain"
          v-for="(item, index) in googleResult"
          :key="index"
        >
          {{ item }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  googleSearch
} from "@/api"
export default {
  name: "GoogleSearch",
  props: ['word', 'isgoogle'],
  data () {
    return {
      googleResult: []
    }
  },
  mounted () {
    // console.log(this.word, this.isgoogle, 'goole')
    // if (this.word) {
    //   this.googleSearch()
    // }
  },
  methods: {
    googleSearch () {
      // console.log(this.word, 'this.word')
      let params = { word: this.word }
      googleSearch(params).then((d) => {
        if (d.code == 200) {
          this.googleResult = d.data
        } else {
          this.googleResult = []
        }

      })
    },
  },
  watch: {
    word: {
      handler (newVal, oldVal) {
        // console.log(newVal, 'newVal')
        if (newVal) {
          this.googleSearch()

        }
      },
      immediate: true
    }
  }

}
</script>

<style>
</style>